













import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/navbar/navbar.vue';
import PageLoader from '@/components/page-loader/page-loader.vue';

@Component({
  components: {
    NavBar,
    PageLoader
  }
})
export default class Root extends Vue {}
