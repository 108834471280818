











import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { MainStore } from '@/store/types';

@Component
export default class PageLoader extends Vue {
  @Getter(MainStore.Getters.getLoading)
  public isLoading!: boolean;

  @Prop({ required: false, default: '64px', type: String })
  public top!: string;
}
